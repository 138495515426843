import React, { Suspense } from "react";
import store from "../../storage/store.js";
import * as dispatches from "../../storage/dispatches.js";
import { hidePopup, showPopup } from "./appController.js";

import Views from "../popups/views/PopupViews.jsx";

import { PopupTitle } from "../popups/popupTitle/PopupTitle.jsx";
import PaymentSuccess from "../popups/paymentSuccess/PaymentSuccess.jsx";

const VerifyRoll = React.lazy(() => import('../popups/verifyRoll/VerifyRoll.jsx'));
const ChangePasswordPopup = React.lazy(() => import('../popups/changePassword/ChangePassword.jsx'));
const EmailSent = React.lazy(() => import('../popups/emailSent/EmailSent.jsx'));
const RegistrationEmailConfirm = React.lazy(() => import('../popups/registrationEmailConfirm/RegistrationEmailConfirm.jsx'));
const ForgotPassword = React.lazy(() => import('../popups/forgotPassword/ForgotPassword.jsx'));
const SendProduct = React.lazy(() => import('../popups/sendProduct/SendProduct.jsx'));
const NewClientSeed = React.lazy(() => import('../popups/newClientSeed/NewClientSeed.jsx'));

export let popupInfo = {
    show({ title, content }) {
        store.dispatch(
            dispatches.popupShow(
                <PopupTitle
                    title={title}
                    onClickBackground={() => {
                        store.dispatch(dispatches.popupHide());
                    }}
                >
                    {content}
                </PopupTitle>
            )
        );
    },
};

export let popupViews = {
    show(views) {
        const popup = (
            <Views
                num={views}
                text={"players looking this box right now!"}
                onClickBackground={hidePopup}
            />
        );
        showPopup(popup);
    },
};

export let newClientSeedPopup = {
    show() {
        const popup = <Suspense><NewClientSeed onClickBackground={hidePopup} /></Suspense>;
        showPopup(popup);
    },
};

export let verifyRollPopup = {
    show(rollData) {
        const popup = (
            <Suspense>
                <VerifyRoll onClickBackground={hidePopup} rollData={rollData} />
            </Suspense>
        );
        showPopup(popup);
    },
};

export let sendProductPopup = {
    show(product) {
        const popup = (
            <Suspense>
            <SendProduct onClickBackground={hidePopup} productItem={product} />
            </Suspense>
        );
        showPopup(popup);
    },
};

export let showPaymentSuccessPopup = {
    show(onClose) {
        const onClickBackground = () => {
            if (onClose) onClose();
            hidePopup();
        }
        showPopup(<PaymentSuccess onClickBackground={onClickBackground} />);
    },
};

export let forgotPasswordPopup = {
    show() {
        const popup = <Suspense><ForgotPassword onClickBackground={hidePopup} /></Suspense>;
        showPopup(popup);
    },
};

export let registrationEmailConfirmPopup = {
    show(email, onClickSendAgain) {
        const popup = (
            <Suspense>
                <RegistrationEmailConfirm
                    onClickBackground={hidePopup}
                    onClickSendAgain={onClickSendAgain}
                    email={email}
                />
            </Suspense>
        );
        showPopup(popup);
    },
};

export let emailSentPopup = {
    show(email, onClickSendAgain) {
        const popup = (
            <EmailSent
                onClickBackground={hidePopup}
                onClickSendAgain={onClickSendAgain}
                email={email}
            />
        );
        showPopup(popup);
    },
};

export let changePasswordPopup = {
    show() {
        const popup = (<Suspense><ChangePasswordPopup onClickBackground={hidePopup} /></Suspense>);
        showPopup(popup);
    },
};

export let paymentPopup = {
    show(location, history, options = {}) {
        if (!location || !history) {
            if (DEV) {
                console.warn("showSignupPopup() - arguments invalid!");
            }
            return;
        }

        const search = new URLSearchParams(location.search);

        search.set('payment', options.amount || "")
        if (options.returnUrl) {
            search.set('returnUrl', options.returnUrl);
        }

        history.push({ search: search.toString() });
    },
    hide(location, history) {
        if (!location || !history) {
            if (DEV) {
                console.warn("hidePaymentPopup() - arguments invalid!");
            }
            return;
        }

        const search = new URLSearchParams(location.search);
        search.delete('payment')
        search.delete('returnUrl')

        history.push({ search: search.toString() });
    },
};

export let signupPopup = {
    show(location, history) {
        if (!location || !history) {
            if (DEV) {
                console.warn("showSignupPopup() - arguments invalid!");
            }
            return;
        }

        const search = new URLSearchParams(location.search);
        search.set('signup', '')

        history.push({ search: search.toString() });
    },
    hide(location, history) {
        if (!location || !history) {
            if (DEV) {
                console.warn("hideSignupPopup() - arguments invalid!");
            }
            return;
        }

        const search = new URLSearchParams(location.search);
        search.delete('signup')
        history.push({ search: search.toString() });
    },
};

export let loginPopup = {
    show(location, history) {
        if (!location || !history) {
            if (DEV) {
                console.warn("showLoginPopup() - arguments invalid!");
            }

            return;
        }

        const search = new URLSearchParams(location.search);
        search.set('login', '')
        history.push({ search: search.toString() });
    },
    hide(location, history) {
        if (!location || !history) {
            if (DEV) {
                console.warn("hideLoginPopup() - arguments invalid!");
            }
            return;
        }

        const search = new URLSearchParams(location.search);
        search.delete('login')
        history.push({ search: search.toString() });
    },
};

export let globalPreloader = {
    show() {
        //store.dispatch(dispatches.showGlobalPreloader(<Preloader />));
    },
    hide() {
        //store.dispatch(dispatches.hideGlobalPreloader());
    },
};

export let mobileMenu = {
    show(location, history) {
        if (!location || !history) {
            if (DEV) {
                console.warn("showMobileMenu() - arguments invalid!");
            }
            return;
        }

        const search = new URLSearchParams(location.search);
        search.set('menu', '')
        history.push({ search: search.toString() });
    },
    hide(location, history) {
        if (!location || !history) {
            if (DEV) {
                console.warn("hideMobileMenu() - arguments invalid!");
            }
            return;
        }

        const search = new URLSearchParams(location.search);
        search.delete('menu')
        history.push({ search: search.toString() });
    },
};

export const handleOpenBox = (box, history, redirect = false) => {
    const { user } = store.getState();

    if (!user.access_token) {
        store.dispatch(dispatches.setBoxToOpen(box));
        history.push(`?signup`);
        return false;
    }

    if (Number(user.balance) < Number(box.price)) {
        store.dispatch(dispatches.setBoxToOpen(box));
        paymentPopup.show(location, history, {
            amount: box.price,
            returnUrl: `${location.origin}/box/${box.name_id}`,
        });
        return false;
    }

    if (redirect) {
        history.push(`/box/${box.name_id}/?open=`);
    }
    return true;
};
