import AppError from "../errors/AppError.js";
import store from "../storage/store.js";
import Loader from '../js/tools/Loader.js';
import ServerAPIQuery from './ServerAPIQuery';
import * as dispatches from "../storage/dispatches.js";
import {USER_TOKEN_KEY} from "../storage/reducers/user.js";
import localStorage from './LocalStorage.js';

export default class ApiLoader{
    static exec (params, localParams){
        return new Promise(((resolve, reject) => {
            Loader.fetch(params).then(response => {
                let queryParams = {};

                try{
                    queryParams = JSON.parse(params.body);
                }
                catch (e) {
                    // console.warn(e.message);
                }

                response.json().then(result => {resolve({response: result, params: queryParams, localParams})});
            }).catch(error => {
                store.dispatch(dispatches.dispatchError(AppError.TYPE.NO_INTERNET));
                reject(error);
            });
        }));
    }

    static passwordResetEmail (params){
        const parameters = {
            method: 'POST',
            url: '/password/reset/email',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(params)
        };

        return {
            exec: ApiLoader.exec.bind(null, parameters),
        };
    }

    static passwordResetPhone (params){
        const parameters = {
            method: 'POST',
            url: '/password/reset/phone',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(params)
        };

        return {
            exec: ApiLoader.exec.bind(null, parameters),
        };
    }

    static passwordUpdatePhone (params){
        const parameters = {
            method: 'POST',
            url: '/password/update/phone',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(params)
        };

        return {
            exec: ApiLoader.exec.bind(null, parameters),
        };
    }

    static passwordUpdateEmail (params){
        const parameters = {
            method: 'POST',
            url: '/password/update/email',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(params)
        };

        return {
            exec: ApiLoader.exec.bind(null, parameters),
        };
    }

    static registrationEmail (params){
        const subParams = JSON.parse(localStorage.getItem('subParams') || '{}');
        const parameters = {
            method: 'POST',
            url: '/registration/email',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ ...params, ...subParams })
        };

        return {
            exec: ApiLoader.exec.bind(null, parameters),
        };
    }

    static verifyPhone (params){
        const parameters = {
            method: 'POST',
            url: '/verify/phone',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(params)
        };

        return {
            exec: ApiLoader.exec.bind(null, parameters),
        };
    }

    static registrationPhonePassword (params){
        const parameters = {
            method: 'POST',
            url: '/registration/phone/process',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(params)
        };

        return {
            exec: ApiLoader.exec.bind(null, parameters),
        };
    }

    static loginFB (user_id, access_token){
        const subParams = JSON.parse(localStorage.getItem('subParams') || '{}');
        const parameters = {
            method: 'POST',
            url: '/auth/fb',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({user_id, access_token, ...subParams})
        };

        return {
            exec: ApiLoader.exec.bind(null, parameters),
        };
    }

    static loginGL (access_token) {
        const subParams = JSON.parse(localStorage.getItem('subParams') || '{}');
        const parameters = {
            method: 'POST',
            url: '/auth/gl',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ access_token, ...subParams })
        };

        return {
            exec: ApiLoader.exec.bind(null, parameters),
        };
    }

    static login (params){
        const parameters = {
            method: 'POST',
            url: '/auth',
            headers: {
                'Authorization': 'Basic '+btoa(`${params.login}:${params.password}`),
            }
        };

        return {
            exec: ApiLoader.exec.bind(null, parameters),
        };
    }

    static privateQueryFetch(){
        let bearerKey = (() => {
            let state = store.getState();

            if(state && state.user && state.user.access_token){
                return state.user.access_token;
            }
            
            return localStorage.getItem(USER_TOKEN_KEY);
        })();

        let query = new ServerAPIQuery();

        let headers = {
            'Content-Type': 'application/json'
        };

        if(bearerKey){
            headers['Authorization'] = 'Bearer '+bearerKey;
        }

        query.exec = function (){
            return ApiLoader.exec({
                method: 'POST',
                url: '/query',
                headers: headers,
                body: query.json()
            }, query.localParams);
        };

        return query;
    }
}

