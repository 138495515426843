export const CURRENCY_SIGN = "₦";

export const SURPRIZE_BOX = {
    name_id: 'surprise-box',
    price: 500,
    title: 'Surprise Box',
}

export const FACEBOOK_APP_ID = "1705942433542246";
export const GOOGLE_CLIENT_ID =
    "428675290983-meatb4tpmisko7ni4nfsoic19srmm0sm.apps.googleusercontent.com";
export const GOOGLE_MAPS_KEY = "AIzaSyABpgiijNGsHQC_8aqYU9udf8xuqHjDFyw";
export const RECAPTCHA_KEY = "6LduwyEqAAAAAN2Vuu_IEHok1QYTday5xEQUlRnJ";
export const MIN_PAYMENT_AMOUNT = 100;

export const DEFAULT_COUNTRY_CODE = "+234";
export const DEFAULT_COUNTRY = "NRA";

export const PAYMENT_METHODS = [
    {
        id: 'bank-transfer',
        title: 'Bank Transfer',
    },
    {
        id: 'visa',
        title: 'VISA',
    }
]

export const DELIVERY_COST = 5000;