export const EVENT_TYPE = {
    REGISTER: 'register',
    DEPOSIT: 'deposit',
    OPEN_BOX: 'open_box',
}

const searchParams = new URLSearchParams(window.location.search);

const sub1 = searchParams.get('sub1') || '';
const sub2 = searchParams.get('sub2') || ''; 
const sub3 = searchParams.get('sub3') || '';
const sub4 = searchParams.get('sub4') || '';

if (sub1 || sub2 || sub3 || sub4) {
    const subParams = { sub1, sub2, sub3, sub4 };
    localStorage.setItem('subParams', JSON.stringify(subParams));
}

export function trackEvent(eventType) {
    const storedParams = JSON.parse(localStorage.getItem('subParams') || '{}');
    if (storedParams) {
        const { sub1 } = storedParams;
        if (sub1 === 't') {
            // Set up event listeners/tracking
            // TODO: Implement event tracking for:
            // - User registration
            // - Account deposit
            // - Box opening
            // Will send events to URL provided later
        }
    }
}