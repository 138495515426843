import React from 'react';
import BasePopup from '../_basePopup/BasePopup.jsx';
import { useSelector } from 'react-redux';
import { CURRENCY_SIGN } from '../../../constants.js';
import successIcon from '../../img/icons/payment_success.svg';

import './paymentSuccess.css';

const PaymentSuccess = ({ onClickBackground }) => {
    const balance = useSelector(state => state.user.balance);

    return (
        <BasePopup onClickBackground={onClickBackground}>
            <div className={`popup-payment-success`}>
                <div className={`popup-success-title`}>
                    <img src={successIcon} alt="success" />
                </div>
                <div className="payment-success-content">
                    <p>Your balance now is <br/><span className="text-red">{CURRENCY_SIGN}{balance}</span><br/><br/>Open your box!</p>
                </div>
            </div>
        </BasePopup>
    );
};

export default PaymentSuccess;