import React, { lazy, Suspense, useEffect } from "react";
import { Provider } from "react-redux";
import { Route, Switch, Redirect, Router } from "wouter-preact";
import * as appController from "./appController.js";

import store from "../../storage/store.js";

import "../css/main.css";
import "../css/fonts.css"

import { isMobileScreen } from "../../mobile-utils.js";
import Base from "../pages/_base/Base.jsx";

import Main from '../pages/main/Main.jsx';
import ScrollToHashElement from "../components/scrollToHashElement/ScrollToHashElement.jsx";
import ErrorViewer from '../components/errorViewer/ErrorViewer.jsx';
import LazyLoadedComponent from "../components/lazyLoad/lazyLoad.jsx";
import "./tracking.js";

// Lazy loading components
const Box = lazy(() => import('../pages/box/Box.jsx'));
const Product = lazy(() => import('../pages/product/Product.jsx'));
const Terms = lazy(() => import('../pages/information/terms/Terms.jsx'));
const Privacy = lazy(() => import('../pages/information/privacy/Privacy.jsx'));
const InformationDelivery = lazy(() => import('../pages/information/delivery/InformationDelivery.jsx'));
const Returns = lazy(() => import('../pages/information/returns/Returns.jsx'));
const Inventory = lazy(() => import('../pages/user/inventory/Inventory.jsx'));
const Profile = lazy(() => import('../pages/user/profile/Profile.jsx'));
const Transactions = lazy(() => import('../pages/user/transactions/Transactions.jsx'));

const App = () => {
    useEffect(() => {
        const resizeEventHandler = () => {
            const isMobileVersion = isMobileScreen();
            appController.setIsMobileVersion(isMobileVersion);
        };

        window.addEventListener("resize", resizeEventHandler);

        resizeEventHandler();

        return () => window.removeEventListener("resize", resizeEventHandler);
    }, []);

    return (
        <Provider store={store}>
            <Base>
                <Suspense>
                    <Switch>
                        <Route path="/" component={Main} />
                        <Route path="/verify/:email_token" component={Main} />
                        <Route path="/box/:name_id" component={Box} />
                        <Route path="/boxes/" component={Box} />
                        <Route path="/product/:name_id/:box_name_id?" component={Product} />
                        <Route path="/delivery/" component={InformationDelivery} />
                        <Route path="/returns/" component={Returns} />
                        <Route path="/terms/" component={Terms} />
                        <Route path="/privacy/" component={Privacy} />
                        <Route path="/payment/" component={Terms} />
                        <Route path="/security/" component={Terms} />
                        <Route path="/inventory/:type" component={Inventory} />
                        <Route path="/profile/" component={() => <Profile edit={true} address={false} />} />
                        <Route path="/address/" component={() => <Profile edit={false} address={true} />} />
                        <Route path="/transactions/" component={Transactions} />
                        <Route path="/password/update/:reset_token" component={Main} />
                        <Route path="/inventory/" component={() => <Redirect to="/inventory/got/" />} />
                        <Route path="*" component={() => <Redirect to="/" />} />
                    </Switch>
                </Suspense>
            </Base>
            <ScrollToHashElement />
            <LazyLoadedComponent triggers={{ load: true, observe: false }} load={() => import("../../services/Socket.js")}>
                {(Socket) => <Socket />}
            </LazyLoadedComponent>
            <ErrorViewer />
        </Provider>
    );
};

export default App;
